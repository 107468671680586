#transaction-history{
    table{
        border-radius: 8px;
        border: 1px solid #D9D9D9;
        thead{
            th.ant-table-cell,td.ant-table-cell{
                // padding: 16px 30px !important;
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 17px;
                color: #B5B5C3;
                background-color: white !important;
            }
        }
        tbody{
            tr{
                td{
                    border-bottom: 1px dashed #D9D9D9;
                }
                &:last-child{
                    td{
                        border: none;
                    }
                }
            }
        }
    }
}

.ant-modal-confirm.ant-modal-confirm-confirm.custom-confirm {
    width: 435px !important;
  }